import React, { useContext, useEffect, useState } from "react";
import ResumeCard from "./ResumeCard";

import { articles } from "../Demo/Skills";
import { fetchResumes } from "../../services/GetResumeService";
import Page404 from "../Page404";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import Cookies from "js-cookie";
import { ThemeContext } from "../../ThemeContext";
const ShowHistory = () => {
  const [resumes, setResumes] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const getResumes = async () => {
      setloading(true);
      try {
        const resumesData = await fetchResumes(Cookies.get("email"));
        console.log(resumesData, "from front res");
        setResumes(resumesData);
        setloading(false);
      } catch (error) {
        console.error("Error fetching resumes:", error);
        setloading(false);
      }
    };

    getResumes();
  }, []);

  const { theme } = useContext(ThemeContext); //

  return (
    <div
      className={`py-6 sm:py-8 lg:py-12 ${
        theme === "light" ? "text-gray-600" : "text-gray-100"
      }`}
    >
      {loading && <Loading work="Loading" />}
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <div className="mb-10 md:mb-16">
          <h2
            className={`mb-4 text-center text-2xl font-bold md:mb-6 lg:text-3xl ${
              theme === "light" ? "text-gray-800" : "text-gray-100"
            }`}
          >
            Resume History
          </h2>
          <p
            className={`mx-auto max-w-screen-md text-center md:text-lg ${
              theme === "light" ? "text-gray-500" : "text-gray-400"
            }`}
          >
            We can store only 5 of your previous resumes
          </p>
        </div>
        <div className="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
          {resumes.map((resume, index) => (
            <ResumeCard
              setResumes={setResumes}
              resumes={resumes}
              key={index}
              id={resume.resume._id}
              link={resume.resume.s3Url}
              name={resume.resume.originalName}
              time={resume.resume.uploadedAt}
            />
          ))}
        </div>
      </div>

      {resumes.length === 0 && (
        <Page404
          main="No Resume History"
          desc="You don't have previous resumes. Match your resume with the job description right away."
          page="resumehistory"
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ShowHistory;
